var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('commission-quota-attainment-groups',{staticClass:"pt-16 pt-sm-0",attrs:{"period":_vm.period,"cc-sm":"12","cc-md":"6","cc-lg":"6"},scopedSlots:_vm._u([{key:"commission-component-group",fn:function(ref){
var group = ref.group;
var item = ref.item;
return [_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('order-detail-scatter-chart',{attrs:{"where":{
          companyId: group.company.id,
          salesAreaId: group.salesArea.id,
          salespersonId: group.salesperson.id,
          commissionQuotaPeriodId: group.commissionQuotaPeriod.id,
          currency: group.currency,
          commissionComponentGroupId: item.commissionComponentGroup.id,
          isPosted: 1,
        },"amount-field":"netUnitPrice","amount-label":("Net Unit Price (" + (group.currency) + ")"),"date-field":"postedDate","date-label":"Posted Date","category-field":"productId","category-finder":_vm.categoryFinder,"title":(item.commissionComponentGroup.abbr + ' Total').toUpperCase(),"start-date":group.commissionQuotaPeriod.startDate,"end-date":group.commissionQuotaPeriod.endDate}})],1)]}},{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('order-detail-scatter-chart',{attrs:{"where":{
          companyId: item.company.id,
          salesAreaId: item.salesArea.id,
          salespersonId: item.salesperson.id,
          commissionQuotaPeriodId: item.commissionQuotaPeriod.id,
          currency: item.currency,
          commissionComponentId: item.commissionComponent.id,
          isPosted: 1,
        },"amount-field":"netUnitPrice","amount-label":("Net Unit Price (" + (item.currency) + ")"),"date-field":"postedDate","date-label":"Posted Date","category-field":"productId","category-finder":_vm.categoryFinder,"title":(
            item.commissionComponent.abbr +
            ' / ' +
            item.commissionComponent.group.abbr
          ).toUpperCase(),"start-date":item.commissionQuotaPeriod.startDate,"end-date":item.commissionQuotaPeriod.endDate}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }