


































































import Vue from 'vue'

import CommissionQuotaAttainmentGroups from '@/components/dashboard/CommissionQuotaAttainmentGroups.vue'
import OrderDetailScatterChart from '@/components/charts/OrderDetailScatterChart.vue'

export default Vue.extend({
  metaInfo: {
    title: 'Unit Price Charts - Dashboard',
  },
  components: {
    CommissionQuotaAttainmentGroups,
    OrderDetailScatterChart,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  methods: {
    categoryFinder(id: number): string {
      return this.$store.getters.getProductById(id).abbr
    },
  },
})
