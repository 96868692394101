
















import Vue, { PropType } from 'vue'

import deepFreeze, { DeepReadonly } from 'deep-freeze'
import { ApexOptions } from 'apexcharts'

// event bus
import MessageBus from '@/message-bus'

// utils
import { currencyFormatter } from '@/utils/format-helpers'

// components
import JChartWrapper from '@/components/controls/JChartWrapper.vue'

// types
import { ID, OrderDetail } from '@/types'
import { isArray, isNumber } from '@/utils/type-helpers'

type Query = {
  [key: string]: number | string
}
type FinderFunction = (id: ID) => string

export default Vue.extend({
  components: {
    JChartWrapper,
  },
  props: {
    where: {
      type: Object as PropType<Query>,
      required: true,
    },

    title: String,
    height: [String, Number],

    startDate: String,
    endDate: String,

    amountField: {
      type: String as PropType<keyof OrderDetail>,
      default: 'netUnitPrice',
    },
    amountLabel: String,
    hideAmountLabel: Boolean,
    dateField: {
      type: String as PropType<keyof OrderDetail>,
      default: 'postedDate',
    },
    dateLabel: String,
    hideDateLabel: Boolean,
    categoryField: {
      type: String as PropType<keyof OrderDetail>,
      required: true,
    },
    categoryFinder: {
      type: Function as PropType<FinderFunction>,
      required: true,
    },
  },
  data: () => ({
    result: undefined as DeepReadonly<OrderDetail[]> | undefined,

    isFetching: 0,
    errors: [] as Error[],

    dialogQuery: undefined as Query | undefined,
  }),
  watch: {
    where: {
      deep: true,
      handler(value) {
        if (value === undefined) {
          this.errors.push(Error('no data'))
        } else {
          this.fetchData()
        }
      },
    },
  },
  computed: {
    currency(): string {
      return this.where.currency.toString() || 'USD'
    },
    startDateTimestamp(): number | undefined {
      return this.startDate ? Date.parse(this.startDate) : undefined
    },
    endDateTimestamp(): number | undefined {
      return this.endDate ? Date.parse(this.endDate) : undefined
    },
    amountLabelValue(): string | undefined {
      if (this.hideAmountLabel) return undefined
      return this.amountLabel || this.amountField
    },
    dateLabelValue(): string | undefined {
      if (this.hideDateLabel) return undefined
      return this.dateLabel || this.dateField
    },
    seriesIds(): number[] {
      if (!this.result) return []
      return [
        ...new Set(
          this.result.map(
            r => r[this.categoryField as keyof OrderDetail] as number
          )
        ),
      ]
    },
    series(): ApexAxisChartSeries {
      if (!this.result) return []
      const result = this.result
      return this.seriesIds.map(id => ({
        name: this.categoryFinder(id),
        data: result
          .filter(r => r[this.categoryField as keyof OrderDetail] === id)
          .map(r => ({
            x: r[this.dateField as keyof OrderDetail],
            y: r[this.amountField as keyof OrderDetail],
          })),
      }))
    },
    chartHeight(): string | number {
      // eslint-disable-next-line prettier/prettier
      return this.height || 'auto'
    },
    chartOptions(): ApexOptions {
      return {
        chart: {
          type: 'scatter',
          animations: {
            enabled: false,
          },
          events: {
            click: (event, chartContext, options) => {
              if (
                options.seriesIndex >= 0 &&
                options.dataPointIndex >= 0 &&
                this.result
              ) {
                const fullWhere = this.where

                fullWhere[this.categoryField] = this.seriesIds[
                  options.seriesIndex
                ]

                const dataPoint = this.series[options.seriesIndex].data[
                  options.dataPointIndex
                ]

                if (dataPoint) {
                  if (isNumber(dataPoint)) {
                    fullWhere[this.dateField] = dataPoint
                    this.dialogQuery = fullWhere
                  } else if (isArray(dataPoint)) {
                    fullWhere[this.dateField] = dataPoint[0]
                    this.dialogQuery = fullWhere
                  } else {
                    fullWhere[this.dateField] = dataPoint.x
                    this.dialogQuery = fullWhere
                  }
                }
              }
            },
          },
        },
        title: {
          text: this.title,
          align: 'left',
        },
        // yaxis or xaxis (for horizontal)
        xaxis: {
          type: 'datetime',
          min: this.startDateTimestamp,
          max: this.endDateTimestamp,
          title: {
            text: this.dateLabelValue,
          },
        },
        yaxis: {
          title: {
            text: this.amountLabelValue,
          },
          labels: {
            formatter: (value: number) =>
              currencyFormatter(this.currency, {
                maximumFractionDigits: 0,
              })((value || 0) / 1000) + 'k',
          },
          min: 0,
        },
        dataLabels: {},
        tooltip: {
          y: {
            formatter: currencyFormatter(this.currency),
          },
        },
      }
    },
  },
  methods: {
    fetchData() {
      this.isFetching++
      this.errors = []

      this.$api.orderDetails
        .list(this.where)
        .then(result => {
          this.result = deepFreeze(result)
        })
        .catch(err => {
          MessageBus.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching--
        })
    },
  },
})
